import React from 'react';
import { Flex } from '../../../components/Flex/Flex';
import Image from "next/legacy/image";
export interface ComoFuncionaCardProps {
  image: string;
  number: number;
  title: string;
  description: string;
}
export const ComoFuncionaCard = ({
  description,
  image,
  number,
  title
}: ComoFuncionaCardProps): JSX.Element => {
  return (
    <Flex className="w-full items-center lg:items-start lg:w-fit flex-col gap-4 lg:gap-[30px]">
      <Flex className=" pl-4 pt-4 rounded-[8px]  h-[168px] bg-[color:var(--bg-primary-pure10)] max-w-[332px] lg:min-w-[300px] w-full">
        <Flex className="relative w-full max-w-[316px] lg:min-w-[284px]">
          <Image
            src={image}
            width={418}
            height={152}
            alt={'title'}
            quality={100}
            priority
          />
        </Flex>
      </Flex>
      <Flex className="hidden lg:block pl-6 z-[5] text-[color:var(--bg-primary-pure)]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="10"
          viewBox="0 0 11 10"
          fill="none"
        >
          <ellipse cx="5.89394" cy="5" rx="4.98769" ry="5" fill="white" />
          <path
            d="M9.88164 5C9.88164 7.21146 8.09397 9 5.89394 9C3.69392 9 1.90625 7.21146 1.90625 5C1.90625 2.78854 3.69392 1 5.89394 1C8.09397 1 9.88164 2.78854 9.88164 5Z"
            stroke="currentColor"
            strokeOpacity="0.4"
            strokeWidth="2"
          />
        </svg>
      </Flex>
      <Flex className="flex-col items-center lg:items-start lg:pl-6 gap-[19px]">
        <p className="font-archivo text-[color:var(--text-primary-pure)]/40 text-[32px] font-[700] leading-[125%]">
          0{number + 1}°
        </p>
        <Flex direction="col" className="max-sm:max-w-[279px] text-center lg:text-start gap-2">
          <p className=" text-neutral-pure1000 h4bold">{title}</p>
          <p className="text-neutral-pure700 bodysm">{description}</p>
        </Flex>
      </Flex>
    </Flex>
  );
};
