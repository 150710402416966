import React from 'react'
import { Flex } from '../../../components/Flex/Flex'
import Icons from '../../../../public/assets/icons'
import { OrgaosReguladoresCarousel } from './OrgaosReguladoresCarousel'

export const OrgaosReguladores = () => {
  return (
    <Flex className='py-8 lg:py-[120px] w-full items-center bg-neutral-pure300 flex-col gap-12 lg:gap-14'>
      <Flex direction='col' className='px-[5%] lg:px-0 items-center gap-6 max-w-[817px]'>
        <p className='text-center h2bold lg:title2 text-neutral-pure1000'>
          Empresa devidamente registrada nos órgãos reguladores
        </p>
        <Icons.Divider className="h-1 w-[57px]" />
      </Flex>
      <OrgaosReguladoresCarousel />
    </Flex>
  )
}
