import React from 'react'
import { Flex } from '../../../components/Flex/Flex'
import Icons from '../../../../public/assets/icons'

interface Props {
  isSelected: boolean
  handleSelect: (value: string) => void
  value: string;
  text: string;
}

export const QualEmpresaItem = ({ isSelected, value, handleSelect, text }: Props) => {
  return (
    <Flex justify='center' align='center' onClick={() => handleSelect(value)} className={`lg:h-[96px] h-20 cursor-pointer w-full rounded-[6px] bg-white relative ${isSelected ? `border-[1.5px] border-[color:var(--border-primary-pure)] shadow-[0px_0px_0px_3px_rgb(var(--shadow-selected)/0.05)]` : `border-[1px] border-neutral-pureOp10`}`}>
      <Flex className={`absolute left-2 top-2 border-[1px] border-neutral-pureOp10  rounded-[100%]`}>
        {isSelected ? (
          <Icons.SelectedEnterprise />
        ) : <div className='border-neutral-pureOp10 h-6 w-6' />}
      </Flex>
      <p className='text-center text-[18px] max-w-[70%] font-[500] leading-[150%] uppercase text-neutral-pure800 p-4'>{text}</p>
    </Flex>
  )
}
