import React from 'react';
import { Flex } from '../../../components/Flex/Flex';
import Image from 'next/legacy/image';
import { Fretamento } from '../Fretamento/Fretamento';
import BackgroundImage from '../../../../public/assets/images/background.png';
import MobileBackgroundImage from '../../../../public/assets/images/mobile-background.png';
import { MobileButtons } from './MobileButtons';
import { useTenantDataProvider } from '../../../providers/TenantProvider/TenantProvider';

export const HomeHero = () => {
  const { data } = useTenantDataProvider();

  return (
    <Flex className=" w-full bg-neutral-pure100 max-[1024px]:justify-center flex-col lg:flex-row min-h-[1005px] lg:min-h-[608px] lg:px-[5%] xl:px-20 min-[1800px]:px-[250px] relative">
      <MobileButtons />
      <Flex className="absolute hidden lg:flex h-full right-0 top-0 max-h-[608px]">
        <Image
          src={data?.whitelabel.banner_img || BackgroundImage.src}
          objectFit="cover"
          objectPosition="right"
          width={3500}
          height={608}
          alt="Banner"
          quality={100}
          priority
        />
      </Flex>
      <Flex className="lg:hidden absolute w-full h-full right-0 top-[96px]">
        <div className="relative w-full h-[calc(100%-96px)]">
          <Image
            className="absolute top-0 left-0  h-full"
            src={data?.whitelabel.banner_img || MobileBackgroundImage.src}
            objectFit="cover"
            objectPosition="top"
            layout="fill"
            alt="Banner"
            quality={100}
            priority
          />
        </div>
      </Flex>
      <Flex className="z-[0] pt-16 lg:pt-20">
        <Fretamento />
      </Flex>
    </Flex>
  );
};
