import React from 'react';
import { Flex } from '../../../components/Flex/Flex';
import Image from "next/legacy/image";
import ComoFunciona1 from '../../../../public/assets/images/como_funciona1.png';
import ComoFunciona2 from '../../../../public/assets/images/como_funciona2.png';
import ComoFunciona3 from '../../../../public/assets/images/como_funciona3.png';
import { ComoFuncionaCard, ComoFuncionaCardProps } from './ComoFuncionaCard';

const cards: Omit<ComoFuncionaCardProps, 'number'>[] = [
  {
    image: ComoFunciona1.src,
    title: 'Cadastre-se em nosso site',
    description: 'Imperdiet adipiscing volutpat, facilisis egestas pharetra. Id eu ultricies velit tristique tra. Id eu ultricies v'

  },
  {
    image: ComoFunciona2.src,
    title: 'Selecione o veículo',
    description:
      'Imperdiet adipiscing volutpat, facilisis egestas pharetra. Id eu ultricies velit tristique tra. Id eu ultricies v'
  },
  {
    image: ComoFunciona3.src,
    title: 'Faça a reserva',
    description: 'Imperdiet adipiscing volutpat, facilisis egestas pharetra. Id eu ultricies velit tristique tra. Id eu ultricies v'

  }
];

export const ComoFunciona = () => {
  return (
    <Flex className=" px-[5%] bg-neutral-pure100 items-center gap-8 lg:gap-14 min-[1280px]:14 xl:px-20 min-[1800px]:px-[250px] py-16 ly:pt-[120px] flex-col">
      <Flex direction="col" className="items-center gap-2 min-[1280px]:gap-4">
        <p className="capsmd font-[700] text-[color:var(--text-primary-pure)] uppercase tracking-[4px]">
          COMO FUNCIONA
        </p>
        <p className="title3 text-neutral-pure1000 text-center">
          Localize a linha ideal para você
        </p>
      </Flex>
      <Flex className="relative max-w-[1188px] flex-col gap-8 lg:gap-[60px] items-center">
        <Flex className="lg:flex-row flex-col min-[1280px]:gap-[96px] max-[1280px]:justify-between max-[1280px]:gap-8 items-center">
          {cards.map((card, index) => (
            <ComoFuncionaCard key={index} number={index} {...card} />
          ))}
        </Flex>
        <div className="lg:block hidden absolute top-[202.5px] w-full h-[1px] bg-[color:var(--bg-primary-pure10)]" />
      </Flex>
    </Flex>
  );
};
