import React from 'react'
import { Flex } from '../../../components/Flex/Flex'
import Image from "next/legacy/image"
// import useWindowWidth from '../../../utils/hooks/useWindowSize'

interface Props {
  logo: string
  image: string
}

export const OrgaoReguladorCard = ({ logo, image }: Props) => {
  // const width = useWindowWidth()
  return (
    <Flex className='bg-white rounded-[8px] bottom-8 left-8  p-8 min-w-[280px] w-[280px] h-[120px]'>
      <Image src={logo} width={180} height={80} objectFit='contain' alt={image} />
    </Flex>
    // <Flex className='min-w-[400px] lg:min-w-[580px] w-[400px] h-[256px] lg:h-[432px] lg:w-[580px] relative rounded-[8px]'>
    //   <Image src={image} width={width < 1024 ? 400 : 580} height={width < 1024 ? 256 : 432} className='rounded-[8px]' objectFit='cover' />
    //   <Flex className='bg-white rounded-[8px] bottom-8 left-8 absolute p-8 w-[280px] h-[120px]'>
    //     <Image src={logo} width={180} height={80} objectFit='contain' />
    //   </Flex>
    // </Flex>
  )
}
