import React from "react";
import { Flex } from "../../../components/Flex/Flex";
import { useCarousel } from "../../../utils/hooks/useCarousel";
import { OrgaoReguladorCard } from "./OrgaoReguladorCard";
import { useTenantDataProvider } from "../../../providers/TenantProvider/TenantProvider";

export const OrgaosReguladoresCarousel = (): JSX.Element => {
  const { data } = useTenantDataProvider();
  const { emblaRef } = useCarousel({
    dragFree: true,
  });

  return (
    <div
      ref={emblaRef}
      className="relative cursor-pointer w-screen gap-8 px-[5%] min-[1800px]:px-0  z-[10] flex-nowrap"
    >
      <Flex className="w-full lg:justify-center  gap-8">
        {data?.certificates.map((card) => (
          <OrgaoReguladorCard key={card.name} image={'/assets/images/emdec_bg.png'} logo={card.logo} />
        ))}
      </Flex>
    </div>
  );
};
