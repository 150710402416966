import React, { useEffect } from 'react';
import { Modal } from '../../../components/Modal/Modal';
import useWindowWidth from '../../../utils/hooks/useWindowSize';
import { Flex } from '../../../components/Flex/Flex';
import Icons from '../../../../public/assets/icons';
import { QualEmpresaItem } from './QualEmpresaItem';
import useStateHandler from '../../../utils/hooks/useStateHandler';
import { useAuth } from '../../../providers/AuthProvider/AuthProvider';
import { useTenantDataProvider } from '../../../providers/TenantProvider/TenantProvider';
import { useRouter } from 'next/router';

interface Props {
  showModal: boolean;
  handleClose: () => void;
}

export const QualEmpresaModal = ({ showModal, handleClose }: Props) => {
  const width = useWindowWidth();
  const { data } = useTenantDataProvider();

  const { selectedCompany, handleSelectCompany } = useAuth();
  const { state: selectedEmpresa, handleState: handleSelectedempresa } =
    useStateHandler<string>('');
  const router = useRouter();

  const handleSubmit = () => {
    handleSelectCompany({ value: selectedEmpresa });
    const url = data?.company_group?.find(
      (company) => company.name === selectedEmpresa
    )?.url;
    if (url) router.push(url);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    handleSelectedempresa(selectedCompany || '');
  }, [selectedCompany]);

  return (
    <Modal
      height="h-fit"
      animate={width < 1024}
      isOpen={showModal}
      handleClose={handleClose}
    >
      <Flex
        className="border-t-[4px] max-h-fit border-[color:var(--border-primary-pure)] w-full  min-h-fit lg:min-w-[864px] max-[1023px]:rounded-b-[0px] rounded-[12px] bg-white p-6 lg:p-16 lg:pb-6 gap-10 items-center"
        direction="col"
      >
        <Icons.BusPin className="h-16 w-16" />
        <Flex direction="col" className="gap-8 lg:gap-10 w-full">
          <Flex direction="col" className="gap-2 lg:gap-3 items-center">
            <p className="capssm font-[700] tracking-[4px] text-[color:var(--text-primary-pure)]">
              ESCOLHER A EMPRESA
            </p>
            <p className="text-center h2bold text-neutral-pure1000">
              Qual empresa deseja fazer o fretamento?
            </p>
          </Flex>
          <Flex direction="col" className="gap-6 lg:gap-10 ">
            <Flex className="flex-col lg:flex-row gap-4 lg:gap-6 w-full">
              {data?.company_group?.map((option) => (
                <QualEmpresaItem
                  key={option.name}
                  value={option.name}
                  text={option.name}
                  isSelected={selectedEmpresa === option.name}
                  handleSelect={handleSelectedempresa}
                />
              ))}
            </Flex>
            <Flex className="justify-end">
              <button
                onClick={handleSubmit}
                disabled={!selectedEmpresa}
                className="disabled:bg-neutral-pure300 disabled:text-neutral-pure1000 w-full lg:w-fit bg-[color:var(--bg-primary-pure)] rounded-[6px] h-12 px-6 text-white h5 font-[500]"
              >
                Finalizar
              </button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
