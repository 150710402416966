import React from 'react';
import { Flex } from '../../../components/Flex/Flex';
import FormTextField from '../../../components/FormTextField/FormTextField';
import Icons from '../../../../public/assets/icons';

import { FretamentoWrapper } from './FretamentoWrapper';
import { useFretamento } from '../../../utils/hooks/useFretamento';
import { useRoteiro } from '../../../providers/RoteiroPersonalizadoProvider/RoteiroPersonalizadoContext';
import { UseFieldArrayAppend } from 'react-hook-form';
import { useBuscaContext } from '../../../providers/BuscaProvider/BuscaProvider';

export const Fretamento = () => {
  const {
    append,
    control,
    errors,
    fields,
    handleSelectedTab,
    handleSubmit,
    onSubmit,
    remove,
    selectedTab
  } = useFretamento({});

  const { isLoading } = useBuscaContext();

  const ButtonInfo = {
    text:
      selectedTab === 'customtrip'
        ? 'Criar roteiro'
        : isLoading
          ? 'Buscando veículos'
          : 'Pesquisar veículos'
  };

  const { dispatch, handleShowModal } = useRoteiro();

  return (
    <>
      <Flex
        className="rounded-t-[12px] z-[1] h-fit shadow-form lg:rounded-[12px] w-full lg:w-fit lg:max-w-[720px] py-8 bg-white dark:bg-white gap-6"
        direction="col"
      >
        <Flex className="gap-2 px-8" direction="col">
          <p className="h2bold lg:title5 font-[700] text-neutral-pure1000">
            Orçamento e reservas online <br/>
            em menos de um minuto
          </p>
        </Flex>
        <form
          className="w-full flex-col flex gap-6"
          onSubmit={handleSubmit((data, event) => {
            event?.preventDefault();
            if (selectedTab === 'customtrip') {
              dispatch({ type: 'REINICIAR_ROTEIRO' });
              handleShowModal(true);
            } else {
              onSubmit(data);
            }
          })}
        >
          <Flex className="px-8 w-full flex-col gap-6">
            <FretamentoWrapper
              isHome
              append={append as unknown as UseFieldArrayAppend<any>}
              control={control}
              errors={errors}
              fields={fields}
              handleSelectedTab={handleSelectedTab}
              remove={remove}
              selectedTab={selectedTab}
            />
          </Flex>
          <Flex className="border-t-[1px] border-neutral-pureOp10 pt-4 pb-8 flex-col gap-6 px-8">
            <Flex className="flex-col lg:flex-row w-full gap-4 ">
              <FormTextField
                className="w-full bg-white"
                name="nome"
                label="Nome completo"
                placeholder="Seu nome"
                control={control}
                errors={errors}
                icon={<Icons.User className="w-[22px] h-[22px]" />}
              />

              <FormTextField
                className="w-full bg-white"
                name="email"
                label="E-mail"
                type="email"
                placeholder="Seu e-mail"
                control={control}
                errors={errors}
                icon={<Icons.Email className="w-[22px] h-[22px]" />}
              />
              <FormTextField
                className="w-full bg-white"
                name="telefone"
                label="Telefone"
                mask="(99) 99999-9999"
                control={control}
                errors={errors}
                icon={<Icons.Phone className="w-[22px] h-[22px]" />}
              />
            </Flex>
            <button
              type="submit"
              disabled={isLoading}
              className="disabled:bg-neutral-pure300 w-full h-14 px-6 bg-[color:var(--bg-primary-pure)] text-neutral-pure100 rounded-[6px]"
            >
              <Flex className="justify-center items-center gap-3">
                {ButtonInfo.text}
                <Icons.Search />
              </Flex>
            </button>
          </Flex>
        </form>
      </Flex>
    </>
  );
};
