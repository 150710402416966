import { GetServerSideProps, NextPage } from 'next';
import serverSideProps from '../utils/getServerSideProps';
import React, { useLayoutEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Flex } from '../components/Flex/Flex';

import { getCookie } from 'cookies-next';
import { QualEmpresaModal } from '../features/Home/Modal/QualEmpresaModal';
import { HomeHero } from '../features/Home/Hero/HomeHero';
import { OrgaosReguladores } from '../features/Home/OrgaosReguladores/OrgaosReguladores';
import { ComoFunciona } from '../features/Home/ComoFunciona/ComoFunciona';
import { Tenant } from '../interfaces/Tenant/tenant';
import { useSetTenantData } from '../utils/hooks/useSetTenantData';

interface Props {
  selectedEmpresa?: string;
  tenantData: Tenant;
}

const Home: NextPage<Props> = ({ selectedEmpresa, tenantData }) => {
  const [showModal, setShowModal] = React.useState(
    !!tenantData.company_group &&
      tenantData.company_group.length > 0 &&
      !selectedEmpresa
  );
  useSetTenantData({ tenantData });

  return (
    <Layout>
      <Flex direction="col" className="w-full">
        <HomeHero />
        <ComoFunciona />
        {/* <PerguntasFrequentes /> */}
        <OrgaosReguladores />
      </Flex>
      <QualEmpresaModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
      />
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const tenantData = await serverSideProps();
  const selectedEmpresa = getCookie(`@${tenantData.name}/selectedCompany`, {
    req: context.req
  });
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=1, stale-while-revalidate=59'
  );
  return {
    ...{
      props: { selectedEmpresa: selectedEmpresa || null, tenantData }
    }
  };
};

export default Home;
